.grey {
    filter: grayscale(100%);
}
.color {
    filter: none;
}
.full-page {
    width: 100%;
    height: 100vh;
    overflow: auto;
}